import * as tslib_1 from "tslib";
import { MatSnackBar } from '@angular/material';
import { ErrorAlertDialogComponent } from '../core/error-alert-dialog/error-alert-dialog.component';
import { TITLE_ERROR, TITLE_SUCCESS } from '../core/constants/oss-insight-constants';
import { DatasourceDataDialogComponent } from '../core/datasource-data-dialog/datasource-data-dialog.component';
import { InsightInfo } from '../model/insight-info';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/material/snack-bar";
var UtilService = /** @class */ (function () {
    function UtilService(titleService, snackBar) {
        this.titleService = titleService;
        this.snackBar = snackBar;
        this.TYPE_MESSAGE = {
            SUCCESS_TYPE: 'success',
            WARN_TYPE: 'warn',
            ERROR_TYPE: 'error',
        };
    }
    UtilService.prototype.setTitle = function (newTitle) {
        this.titleService.setTitle(newTitle);
    };
    UtilService.prototype.getIpAddress = function () {
        return ('198.168.142.153');
    };
    UtilService.prototype.getBrowserUserAgent = function () {
        return window.navigator.userAgent;
    };
    UtilService.prototype.getDeviceId = function () {
        return '31241234235345';
    };
    UtilService.prototype.getClientId = function () {
        return '159617a323cdb0dfd8025814c2676f202c1e513b';
    };
    UtilService.prototype.getSecretKey = function () {
        return 'e07082e4faae7d8e6bbe4cbb5680b6adf4460539';
    };
    UtilService.prototype.buildOssPidRequestBody = function (ossPidRequest) {
        return { osspidRequest: ossPidRequest };
    };
    UtilService.prototype.buildDetailReqObj = function (detailObj) {
        return { osspidRequest: detailObj };
    };
    UtilService.prototype.showReqRes = function (name, url, reqBody, res) {
    };
    UtilService.prototype.convertPipeIncludedStringToArray = function (st) {
        var data = st.split('||');
        var dataArray = [];
        for (var j = 0; j < data.length; j++) {
            dataArray.push(data[j]);
        }
        return dataArray;
    };
    UtilService.prototype.convertPipeIncludedStringToNumberArray = function (st) {
        var data = st.split('||');
        var dataArray = [];
        for (var j = 0; j < data.length; j++) {
            dataArray.push(Number(data[j]));
        }
        return dataArray;
    };
    UtilService.prototype.convertPipeStringToValueArray = function (st) {
        var data = st.split('||');
        var dataArray = [];
        var x;
        for (var j = 0; j < data.length; j++) {
            x = data[j];
            dataArray.push(this.convertValueToString(x));
        }
        return dataArray;
    };
    UtilService.prototype.convertValueToString = function (value) {
        var retString;
        if (value >= 1.0e+9) {
            retString = (Math.abs(Number(value)) / 1.0e+9).toFixed(2).toLocaleString() + ' Billion';
        }
        else if (value >= 1.0e+6) {
            retString = (Math.abs(Number(value)) / 1.0e+6).toFixed(2).toLocaleString() + ' Million';
        }
        else {
            retString = Math.abs(value).toLocaleString();
        }
        if (retString === '0') {
            retString = '';
        }
        return retString;
    };
    UtilService.prototype.getDataFromArray = function (dataArray, index) {
        if (dataArray.length > 0) {
            return dataArray[index];
        }
        else {
            return '';
        }
    };
    UtilService.prototype.buildParamText = function (resData) {
        var data = resData.data;
        var text;
        text = '';
        if (data) {
            if (data.__param) {
                text += data.__param + ' || ';
            }
            if (data.__param2) {
                text += data.__param2 + ' || ';
            }
        }
        if (resData.userEmail) {
            text += data.email + ' || ';
        }
        if (text) {
            text = text.substr(0, text.length - 3);
            text = 'with parameter ' + text;
        }
        return text;
    };
    UtilService.prototype.showUpdateSuccess = function (dialog, data) {
        this.showMessage(dialog, TITLE_SUCCESS, data, false, '');
    };
    UtilService.prototype.showUpdateUnsuccessful = function (dialog, msg, err) {
        this.showMessage(dialog, TITLE_ERROR, msg + "\n" + err, true, err);
    };
    UtilService.prototype.showErrorMessage = function (dialog, msg) {
        this.showMessage(dialog, TITLE_ERROR, msg, false, '');
    };
    /**
     * @param title (title of the dialog)
     * @param details (details of the dialog)
     * @param shouldShow (false means 'Error Details' button will not be shown, true means it will be shows)
     */
    UtilService.prototype.showMessage = function (dialog, title, details, shouldShow, errorDetails) {
        var dialogRef = dialog.open(ErrorAlertDialogComponent, {
            disableClose: true,
            width: '300px',
            height: '200px',
            data: {
                details: details,
                title: title,
                shouldShow: shouldShow,
                errorDetails: errorDetails
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // console.log('The dialog was closed');
        });
    };
    UtilService.prototype.showDataSourceData = function (dialog, title, details, shouldShow, errorDetails, databaseVersion, databaseTime, proxyServerVersion, sshTunnelServiceVersion) {
        var dialogRef = dialog.open(DatasourceDataDialogComponent, {
            disableClose: true,
            width: '600px',
            height: '480px',
            data: {
                details: details,
                title: title,
                shouldShow: shouldShow,
                errorDetails: errorDetails,
                databaseVersion: databaseVersion,
                databaseTime: databaseTime,
                proxyServerVersion: proxyServerVersion,
                sshTunnelServiceVersion: sshTunnelServiceVersion
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // console.log('The dialog was closed');
        });
    };
    UtilService.prototype.showErrorDetails = function (dialog, title, details, shouldShow, errorDetails) {
        var dialogRef = dialog.open(ErrorAlertDialogComponent, {
            disableClose: true,
            width: '500px',
            height: '500px',
            data: {
                details: details,
                title: title,
                shouldShow: shouldShow,
                errorDetails: errorDetails
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // console.log('The dialog was closed');
        });
    };
    UtilService.prototype.showErrorDetailsForVerifyError = function (dialog, title, details, shouldShow, errorDetails) {
        var dialogRef = dialog.open(ErrorAlertDialogComponent, {
            disableClose: true,
            width: '500px',
            height: '250px',
            data: {
                details: details,
                title: title,
                shouldShow: shouldShow,
                errorDetails: errorDetails
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // console.log('The dialog was closed');
        });
    };
    UtilService.prototype.replaceTitle = function (title, parentTitle, parentValue) {
        title = title.replace('$__parent_title', (parentTitle ? this.replaceTitle(parentTitle, '', '') : ''));
        title = title.replace('$__parent_value', (parentValue ? parentValue : ''));
        return title;
    };
    UtilService.prototype.generateErrorMsg = function (error) {
        var e_1, _a, e_2, _b;
        var response = '';
        var fieldErrors = error['error']['fieldErrors'];
        var generalErrors = error['error']['generalErrors'];
        if (fieldErrors) {
            var values = Object.keys(fieldErrors).map(function (key) { return fieldErrors[key]; });
            try {
                for (var values_1 = tslib_1.__values(values), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
                    var o = values_1_1.value;
                    response += o + '<br />';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (values_1_1 && !values_1_1.done && (_a = values_1.return)) _a.call(values_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (generalErrors) {
            try {
                for (var generalErrors_1 = tslib_1.__values(generalErrors), generalErrors_1_1 = generalErrors_1.next(); !generalErrors_1_1.done; generalErrors_1_1 = generalErrors_1.next()) {
                    var o = generalErrors_1_1.value;
                    response += o + '<br />';
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (generalErrors_1_1 && !generalErrors_1_1.done && (_b = generalErrors_1.return)) _b.call(generalErrors_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        else {
            response = error['error']['message'];
        }
        console.log("final-response", response);
        return response;
    };
    UtilService.prototype.convertToDate = function (data) {
        if (data) {
            var date = new Date(data);
            return date.toLocaleString('en-US', { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        }
        else {
            return data;
        }
    };
    UtilService.prototype.convertToDateWithYear = function (data) {
        if (data) {
            var date = new Date(data);
            return date.toLocaleString('en-GB', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', year: 'numeric', hour12: false, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        }
        else {
            return data;
        }
    };
    UtilService.prototype.errorInsightInfo = function () {
        var insightInfo = new InsightInfo();
        insightInfo.errorLog = true;
        return insightInfo;
    };
    UtilService.prototype.errorHandler = function (error) {
        var errorLog = this.errorInsightInfo();
        if (error['status'] === 304) {
            errorLog.debugData = 'This resource already updated by other';
        }
        else {
            errorLog.debugData = this.generateErrorMsg(error);
        }
        return errorLog;
    };
    UtilService.prototype.isNumericString = function (str) {
        if (typeof str != "string" || str === '')
            return false; // we only process strings!
        str = str.replace(/,/g, '');
        return !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    };
    UtilService.prototype.openAndroidAppDownloadPage = function () {
        window.open("https://play.google.com/store/apps/details?id=com.bat.insight");
    };
    UtilService.prototype.openIosAppDownloadPage = function () {
        window.open("https://apps.apple.com/us/app/insightdb/id1499189318");
    };
    UtilService.prototype.openInsightdbPage = function () {
        window.open("https://ba-systems.com/product/insightdb");
    };
    UtilService.prototype.closCurrentTab = function () {
        window.close();
    };
    UtilService.prototype.showSnackBarMessage = function (message, type) {
        this.snackBar.open(message, '', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: [type === this.TYPE_MESSAGE.SUCCESS_TYPE ? 'mat-green-snackbar'
                    : (type == this.TYPE_MESSAGE.ERROR_TYPE ? 'mat-red-snackbar' : 'mat-orange-snackbar')]
        });
    };
    UtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilService_Factory() { return new UtilService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i2.MatSnackBar)); }, token: UtilService, providedIn: "root" });
    return UtilService;
}());
export { UtilService };
