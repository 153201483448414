import { BehaviorSubject } from 'rxjs/index';
import * as i0 from "@angular/core";
var DashboardObjRefreshDataService = /** @class */ (function () {
    function DashboardObjRefreshDataService() {
        this.dataSource = new BehaviorSubject(null);
        this.data = this.dataSource.asObservable();
    }
    DashboardObjRefreshDataService.prototype.updatedData = function (data) {
        this.dataSource.next(data);
    };
    DashboardObjRefreshDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardObjRefreshDataService_Factory() { return new DashboardObjRefreshDataService(); }, token: DashboardObjRefreshDataService, providedIn: "root" });
    return DashboardObjRefreshDataService;
}());
export { DashboardObjRefreshDataService };
