import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService() {
        this.storage = localStorage;
    }
    StorageService.prototype.save = function (key, value) {
        value = JSON.stringify(value);
        this.storage.setItem(key, value);
    };
    StorageService.prototype.read = function (key) {
        var value = this.storage.getItem(key);
        return JSON.parse(value);
    };
    StorageService.prototype.remove = function (key) {
        return this.storage.removeItem(key);
    };
    StorageService.prototype.clear = function () {
        this.storage.clear();
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
