import * as tslib_1 from "tslib";
import { ReqBody } from "./req-body";
var OssPidRequest = /** @class */ (function (_super) {
    tslib_1.__extends(OssPidRequest, _super);
    function OssPidRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OssPidRequest;
}(ReqBody));
export { OssPidRequest };
