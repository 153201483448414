import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiEndpoints } from 'src/app/api-endpoints';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var client = 'client';
var api = 'api';
var apiBank = 'bank-info';
var apiSearch = 'api/search';
var clientSearch = 'client/search';
var apiBankSearch = 'bank-info/search';
var jwtToken = 'token';
var bankDelete = 'api-bank';
var bankList = 'api-bank/bank-list-by-api';
var apiList = 'api-bank/api-list-by-bank/';
var clientList = 'client-banks/client-list-by-bank/';
var apiListForAddInBank = 'api/list-for-add-by-bank-oid/';
var apiBankListForAddInApi = 'bank-info/list-for-add-by-api-oid/';
var clientListForAddInBank = 'client/list-for-add-by-bank-oid/';
var apiAdd = 'api-bank';
var clientAdd = 'client-banks';
var clientDelete = 'client-banks';
var clientBalance = 'client-balance';
var apiVerify = 'api/verify';
var api_bank_list_by_client_id = 'client-banks/bank-list-by-client/';
var search = '/search/';
var apiBankListForAddInClient = 'bank-info/list-for-add-by-client-oid/';
var ApiBankHelperService = /** @class */ (function () {
    function ApiBankHelperService(http) {
        this.http = http;
        this.apiEndpoints = new ApiEndpoints();
    }
    ApiBankHelperService.prototype.getJWtToken = function () {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + jwtToken;
        // dami url
        // let url = "http://192.168.30.83:5082/token";
        return this.http.get(url, {}).pipe(map(function (value) { return value; }));
    };
    // Api
    // Api-list
    ApiBankHelperService.prototype.getApiObjTableData = function (page, size) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    // Api table Data Filter
    ApiBankHelperService.prototype.getAPITableDataForSearch = function (page, size, filterValue) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiSearch + '/' + filterValue;
        // let url =  'http://192.168.30.83:8701/api' + '/' + search + '/' + filterValue;
        // const headers = { 'Authorization': 'Bearer ' + jwtToken};
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getAPIByOid = function (oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api + '/' + oid;
        return this.http.get(url).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getClientByOid = function (oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client + '/' + oid;
        return this.http.get(url).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getApiBankByOid = function (oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank + '/' + oid;
        return this.http.get(url).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getClientInfoByOid = function (oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client + '/info/' + oid;
        return this.http.get(url).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getClientTableDataForSearch = function (page, size, filterValue) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientSearch + '/' + filterValue;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getAPIBankTableDataForSearch = function (page, size, filterValue) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankSearch + '/' + filterValue;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getClientObjTableData = function (page, size) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getApiBankObjTableData = function (page, size) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getApiBankTableDatabyApiid = function (page, size, oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + bankList + '/' + oid;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getAPIBankListByClientId = function (page, size, clientOid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api_bank_list_by_client_id + clientOid;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getApiListForDisplayUsingBankOid = function (page, size, oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiList + oid;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getClientListForDisplayUsingBankOid = function (page, size, oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientList + oid;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getAPIListForAddUsingBankOid = function (page, size, oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiListForAddInBank + oid;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getAPIBankListForAddUsingAPIOid = function (page, size, oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankListForAddInApi + oid;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getAPIBankListForAddUsingClientOid = function (page, size, oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankListForAddInClient + oid;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.getClientForAddUsingBankOid = function (page, size, oid) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientListForAddInBank + oid;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.createApiObj = function (apiObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.createClientObj = function (apiObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.addBalanceObj = function (Obj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientBalance;
        Obj.oid = null;
        return this.http.post(fullUrl, Obj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.createApiBankObj = function (apiObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.addAPIForApiBank = function (apiObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiAdd;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.addClientForApiBank = function (apiObj) {
        // let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientAdd;
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientAdd;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.updateApiObj = function (oid, apiObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api + '/' + oid;
        apiObj.oid = oid;
        return this.http.put(fullUrl, apiObj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.updateClientObj = function (oid, apiObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client + '/' + oid;
        apiObj.oid = oid;
        return this.http.put(fullUrl, apiObj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.updateAPIBankObj = function (oid, apiObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank + '/' + oid;
        apiObj.oid = oid;
        return this.http.put(fullUrl, apiObj).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.removePresentationItemForAPIBank = function (oid) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + bankDelete + '/' + oid;
        return this.http.delete(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.removePresentationItemForAPI = function (oid) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + bankDelete + '/' + oid;
        return this.http.delete(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.removePresentationItemForClient = function (oid) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientDelete + '/' + oid;
        return this.http.delete(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.verifyDataSourceUsingQueryParams = function (obj, paramsObj) {
        var e_1, _a;
        var Params = new HttpParams();
        try {
            for (var _b = tslib_1.__values(Object.entries(paramsObj)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
                Params = Params.append("" + key, "" + value);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiVerify;
        return this.http.post(fullUrl, obj, { params: Params }).pipe(map(function (value) { return value; }));
    };
    //search-apis
    ApiBankHelperService.prototype.apiGetAPIBankDataForSearch = function (page, size, oid, filterValue) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankListForAddInApi + oid + search + filterValue;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.clientGetAPIBankDataForSearch = function (page, size, oid, filterValue) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankListForAddInClient + oid + search + filterValue;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.apiBankgetClientDataForSearch = function (page, size, oid, filterValue) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientListForAddInBank + oid + search + filterValue;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.prototype.apiBankgetAPIForSearch = function (page, size, oid, filterValue) {
        var url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiListForAddInBank + oid + search + filterValue;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    ApiBankHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiBankHelperService_Factory() { return new ApiBankHelperService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiBankHelperService, providedIn: "root" });
    return ApiBankHelperService;
}());
export { ApiBankHelperService };
