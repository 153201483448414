import { Subject } from 'rxjs/index';
import { ApiEndpoints } from '../../../api-endpoints';
import { map } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../core/shared/service/local-storage-service";
import * as i3 from "../../../core/shared/service/data-service";
var accessLogListUrl = 'access-log/get-list-by-user-id';
var dashboardGetListForUserByIdUrl = 'dashboard/get-list-for-user-by-id';
var accessLogLogoutUrl = 'access-log/logout';
var DashboardService = /** @class */ (function () {
    function DashboardService(httpClient, localStorageService, dataService) {
        this.httpClient = httpClient;
        this.localStorageService = localStorageService;
        this.dataService = dataService;
        this.apiEndPoint = new ApiEndpoints();
    }
    DashboardService.prototype.getDataForPostReq = function (fullUrl, ossPidRequest) {
        return this.httpClient.post(fullUrl, ossPidRequest);
    };
    DashboardService.prototype.getDataForDashboard = function (dashboardId, filterKey) {
        var url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.dashboardServiceUrl + 'dashboard/get-data';
        var data = { id: dashboardId, filterKey: filterKey };
        return this.httpClient.post(url, data, { observe: 'response' });
        //  --> to get response header
    };
    /*
    checkDatabaseConnection(dataSource: DataSource){
      let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.utilityServiceUrl + checkDbConnectionUrl;
      return this.http.post<DbConnectionInfo>(fullUrl, dataSource);
  
      getUserTableData(page: number, size: number): Observable<any> {
      let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + userGetListUrl;
      // let url = 'http://192.168.152.82:8082/'+userGetListUrl;
      const fullUrl = `${url}?page=${page}&size=${size}`;
      return this.http.post(fullUrl, {}).pipe(
        map(value => value)
      );
    }
    }*/
    DashboardService.prototype.saveMaximizeData = function (userWiserDashboardView) {
        var fullUrl = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'uwdv/create';
        return this.httpClient.post(fullUrl, userWiserDashboardView).pipe(map(function (value) { return value; }));
    };
    DashboardService.prototype.getConnectivityData = function () {
        var observable = new Subject();
        this.httpClient.get(this.apiEndPoint.connectivityUrl).subscribe(function (response) {
            observable.next(response);
        }, function (error) {
            observable.next(error);
        });
        return observable;
    };
    DashboardService.prototype.getStaticData = function (fullUrl) {
        return this.httpClient.get(fullUrl);
    };
    DashboardService.prototype.saveDashboardObjAndRefreshDashboardData = function (dashboards) {
        var dashboardObjList = new Array();
        dashboardObjList = dashboards;
        this.localStorageService.saveDashboardObjs(dashboardObjList);
        this.dataService.updatedData(dashboardObjList);
    };
    DashboardService.prototype.getAccessLogList = function (userId, page, size) {
        var data = { id: userId };
        var url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + accessLogListUrl;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.httpClient.post(fullUrl, data).pipe(map(function (value) { return value; }));
    };
    DashboardService.prototype.getDashboardObjAccessList = function (userId, page, size) {
        var data = { id: userId };
        var url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'dashboard-obj/dashboard-obj-access-info';
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.httpClient.post(fullUrl, data).pipe(map(function (value) { return value; }));
    };
    // getDashboardObjAccessList(userId: any,page:any,size:any) {
    //   const fullUrl1 = this.apiEndPoint.profileInfoUrl;
    //   const reqBody = {};
    //   this.httpClient.post(fullUrl1, reqBody).toPromise().then(res => {
    //     console.log('First Promise resolved ----- ', res);
    //     if (res['data']['content']['id']) {
    //       let data = {id: userId};
    //       let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'dashboard-obj/dashboard-obj-access-info';
    //       const fullUrl = `${url}?page=${page}&size=${size}`;
    //       return this.httpClient.post(fullUrl, data).pipe(
    //         map(value => value)
    //       );
    //       // this.httpClient.post(fullUrl, data).toPromise().then(data => {
    //       //   console.log('Second Promise resolved ----', data)
    //       // });
    //     }
    //   });
    // }
    DashboardService.prototype.getDashboardDataForUserById = function (page, size, profile) {
        var url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + dashboardGetListForUserByIdUrl;
        var fullUrl = url + "?page=" + page + "&size=" + size;
        return this.httpClient.post(fullUrl, profile).pipe(map(function (value) { return value; }));
    };
    DashboardService.prototype.getAccessLogLogoutData = function (data) {
        var fullUrl = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + accessLogLogoutUrl;
        return this.httpClient.post(fullUrl, data).pipe(map(function (value) { return value; }));
    };
    DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.DashboardObjRefreshDataService)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
