import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.service";
var AuthGurd = /** @class */ (function () {
    function AuthGurd(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    AuthGurd.prototype.canActivate = function () {
        if (this.authService.isLoginIn() != undefined) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    };
    AuthGurd.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGurd_Factory() { return new AuthGurd(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService)); }, token: AuthGurd, providedIn: "root" });
    return AuthGurd;
}());
export { AuthGurd };
