import { ComponentFactoryResolver, TemplateRef, ViewContainerRef } from "@angular/core";
import { MatProgressSpinner } from "@angular/material";
/**
 * This directive swaps the attached template for a spinner based upon some expression
 * @example `<mat-icon *appSpinOn="isLoading"></mat-icon>`
 */
var SpinOnDirective = /** @class */ (function () {
    function SpinOnDirective(componentFactoryResolver, templateRef, viewContainer) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.hasView = false;
    }
    Object.defineProperty(SpinOnDirective.prototype, "appSpinOn", {
        set: function (condition) {
            if (!condition && !this.hasView) {
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            }
            else if (condition && this.hasView) {
                this.viewContainer.clear();
                this.addSpinner();
                this.hasView = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    SpinOnDirective.prototype.addSpinner = function () {
        var componentFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
        this.viewContainer.clear();
        var componentRef = this.viewContainer.createComponent(componentFactory);
        componentRef.instance.diameter = 18;
        componentRef.instance.color = 'accent';
        componentRef.instance.mode = 'indeterminate';
        //   componentRef.instance._elementRef.nativeElement.classList.add('button-spinner');
        componentRef.instance._elementRef.nativeElement.setAttribute('style', 'vertical-align: middle; display: inline-block;');
    };
    return SpinOnDirective;
}());
export { SpinOnDirective };
