import { DefaultLayoutComponent } from './core/default-layout/default-layout.component';
var APP_CONTAINERS = [
    DefaultLayoutComponent
];
var ɵ0 = {}, ɵ1 = [];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
