import { Observable } from 'rxjs';
import { ApiEndpoints } from './../api-endpoints';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OssService = /** @class */ (function () {
    function OssService(httpClient) {
        this.httpClient = httpClient;
        this.apiEndpoints = new ApiEndpoints();
    }
    OssService.prototype.ossVerification = function (ossPidRequest) {
        return this.httpClient.post(this.apiEndpoints.ossVerificationUrl, ossPidRequest);
    };
    OssService.prototype.ossLogout = function (ossPidRequest) {
        return this.httpClient.post(this.apiEndpoints.ossLogOutUrl, ossPidRequest);
    };
    OssService.prototype.userStatusCheck = function (ossPidRequest) {
        return this.httpClient.post(this.apiEndpoints.statusCheckUrl, ossPidRequest);
    };
    OssService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OssService_Factory() { return new OssService(i0.ɵɵinject(i1.HttpClient)); }, token: OssService, providedIn: "root" });
    return OssService;
}());
export { OssService };
