import { OssPidRequest } from '../model/oss-pid-request';
import { RequestData } from '../model/request-data';
import { REQUEST_TYPE, VERSION } from '../core/constants/oss-insight-constants';
import { LoginInfoBean } from '../modules/dashboard/model/login-info-bean';
import * as i0 from "@angular/core";
import * as i1 from "./util.service";
import * as i2 from "../core/shared/service/local-storage-service";
var OssHelperService = /** @class */ (function () {
    function OssHelperService(utilService, localStorageService) {
        this.utilService = utilService;
        this.localStorageService = localStorageService;
    }
    OssHelperService.prototype.buildOssPidRequest = function () {
        var ossPidRequest = new OssPidRequest();
        ossPidRequest.requestData = new RequestData();
        ossPidRequest.requestData.oauth_data = this.localStorageService.getOauthData();
        ossPidRequest.requestData.oauth_token = this.localStorageService.getOauthToken();
        ossPidRequest.requestData.ipAddress = this.utilService.getIpAddress();
        ossPidRequest.requestData.userAgent = this.utilService.getBrowserUserAgent();
        var dashboardId = this.localStorageService.getCurrentDashboard().id;
        ossPidRequest.requestData.dashboardId = dashboardId ? dashboardId : '1';
        ossPidRequest.requestData.id = dashboardId ? dashboardId : '1';
        ossPidRequest.requestType = REQUEST_TYPE;
        ossPidRequest.version = VERSION;
        return ossPidRequest;
    };
    OssHelperService.prototype.buildOssPidRequestForCallBack = function () {
        var ossPidRequest = new OssPidRequest();
        ossPidRequest.requestData = new RequestData();
        ossPidRequest.requestData.oauth_data = this.localStorageService.getOauthData();
        ossPidRequest.requestData.oauth_token = this.localStorageService.getOauthToken();
        ossPidRequest.requestData.ipAddress = this.utilService.getIpAddress();
        ossPidRequest.requestData.userAgent = this.utilService.getBrowserUserAgent();
        ossPidRequest.requestType = REQUEST_TYPE;
        ossPidRequest.version = VERSION;
        return ossPidRequest;
    };
    OssHelperService.prototype.buildOssPidRequestLoginForCallBack = function () {
        var ossPidRequest = new LoginInfoBean();
        ossPidRequest.oauth_data = this.localStorageService.getOauthData();
        ossPidRequest.oauth_token = this.localStorageService.getOauthToken();
        return ossPidRequest;
    };
    OssHelperService.prototype.buildOssPidRequestLogout = function () {
        var ossPidRequest = new LoginInfoBean();
        ossPidRequest.oauth_data = this.localStorageService.getOauthData();
        ossPidRequest.oauth_token = this.localStorageService.getOauthToken();
        return ossPidRequest;
    };
    OssHelperService.prototype.buildRequestBodyForComponentWiseData = function (rb) {
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    id: rb.id,
                    filter_key: this.localStorageService.getDashboardFilterKey(),
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForChartData = function (rb) {
        var data = { dbObjId: rb.dbObjId,
            filterKey: this.localStorageService.getDashboardFilterKey(),
        };
        return data;
    };
    OssHelperService.prototype.buildRequestBodyForChartDataInside = function (dbObjId) {
        var data = { dbObjId: dbObjId,
            filterKey: this.localStorageService.getDashboardFilterKey() };
        return data;
    };
    OssHelperService.prototype.buildRequestBodyForChartInsideData = function (rb) {
        var data = { dbObjId: rb.child_obj_id,
            filterKey: this.localStorageService.getDashboardFilterKey(),
            __param: rb.__param,
            __param2: rb.__param2,
            parentTitle: rb.responseData.title,
            parentValue: rb.responseData.value
        };
        return data;
    };
    OssHelperService.prototype.buildOssPidRequestForChronologyData = function () {
        var ossPidRequest = this.buildOssPidRequest();
        // let paramData: string[] = codeStatus.paramData.split(',');
        ossPidRequest.requestData.position = 5001;
        ossPidRequest.requestData.dashboardId = this.localStorageService.getCurrentDashboard().id;
        ossPidRequest.requestData.filter_key = this.localStorageService.getDashboardFilterKey();
        return ossPidRequest;
    };
    OssHelperService.prototype.butildRequstDataForInsideDetail = function (parentChartData) {
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    token: '',
                    position: '503',
                    filter_key: this.localStorageService.getDashboardFilterKey(),
                    parent_obj_id: parentChartData.parent_obj_id,
                    child_obj_id: parentChartData.child_obj_id,
                    __param: parentChartData.__param,
                    __param2: parentChartData.__param2
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildCommonRequestBody = function () {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent()
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForAssignDashboardByUserId = function (userId, dashboardId) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_id: userId,
                    dashboardId: dashboardId
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForChangeAdminStatusByUserId = function (userId, admin) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_id: userId,
                    admin: admin
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForChangeUserStatusByUserId = function (userId, userStatus) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_id: userId,
                    user_status: userStatus
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForEnableDisableDeviceByUserId = function (userId) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_id: userId
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForAssignDashboardObject = function (dashboardObjId, dashboardId) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    dashboard_object_id: dashboardObjId,
                    dashboardId: dashboardId
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForAssignUserType = function (userTypeId, dashboardId) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_type: userTypeId,
                    dashboardId: dashboardId
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestForDeviceDisplay = function (tvDashboard) {
        var reqBody = {
            osspidRequest: {
                requestType: 'APP_DASHBOARD',
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    token: '',
                    id: tvDashboard.id,
                    code1: tvDashboard.code1,
                    code2: tvDashboard.code2,
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForCreatingDashboard = function (dashboardTableData) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    dashboard: {
                        caption: dashboardTableData.caption,
                        desktop: dashboardTableData.desktop,
                        mobile: dashboardTableData.mobile,
                        objId: dashboardTableData.objId,
                        status: dashboardTableData.status,
                        timeline: dashboardTableData.timeline,
                        totalDurationTv: dashboardTableData.totalDurationTv,
                        tv: dashboardTableData.tv
                    }
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForCreatingUserType = function (userTypeListData) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_type_obj: {
                        id: userTypeListData.id,
                        typeName: userTypeListData.typeName,
                        status: userTypeListData.status
                    },
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForUpdatingDashboard = function (dashboardTableData) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    dashboard: {
                        id: dashboardTableData.id,
                        caption: dashboardTableData.caption,
                        desktop: dashboardTableData.desktop,
                        mobile: dashboardTableData.mobile,
                        objId: dashboardTableData.objId,
                        status: dashboardTableData.status,
                        timeline: dashboardTableData.timeline,
                        totalDurationTv: dashboardTableData.totalDurationTv,
                        tv: dashboardTableData.tv
                    }
                }
            }
        };
        return reqBody;
    };
    OssHelperService.prototype.buildRequestBodyForUpdatingUserType = function (userTypeListData) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        var reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_type_obj: {
                        id: userTypeListData.id,
                        typeName: userTypeListData.typeName,
                        status: userTypeListData.status
                    },
                }
            }
        };
        return reqBody;
    };
    OssHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OssHelperService_Factory() { return new OssHelperService(i0.ɵɵinject(i1.UtilService), i0.ɵɵinject(i2.LocalStorageService)); }, token: OssHelperService, providedIn: "root" });
    return OssHelperService;
}());
export { OssHelperService };
