import { ApiEndpoints } from '../../api-endpoints';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../utils/oss-helper.service";
var insightDashboardServiceEndPoint = 'dashboard-obj/get-data';
var insightDashboardServiceEndPointError = 'dashboard-obj/info';
var serviceInfoUrl = 'common/version';
var RequestUtilService = /** @class */ (function () {
    function RequestUtilService(http, ossHelperService) {
        this.http = http;
        this.ossHelperService = ossHelperService;
        this.apiEndpoints = new ApiEndpoints();
    }
    RequestUtilService.prototype.enableDevice = function (url, rb) {
        return this.http.post(url, rb);
    };
    RequestUtilService.prototype.getChartResponseData = function (url, rb) {
        var componentWiseRequest = this.ossHelperService.buildRequestBodyForComponentWiseData(rb);
        return this.http.post(url, componentWiseRequest);
        /*
        Initially only dashboard obj sent with req body.
        After changing api,
        common req body with dashboard id and filter key sent for component wies request.
        */
        // return this.http.post(url, rb);
    };
    RequestUtilService.prototype.getChartData = function (dashBoardObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
        var data = this.ossHelperService.buildRequestBodyForChartData(dashBoardObj);
        return this.http.post(fullUrl, data);
    };
    RequestUtilService.prototype.getChartDataForInside = function (dbObjId) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + insightDashboardServiceEndPointError;
        var data = this.ossHelperService.buildRequestBodyForChartDataInside(dbObjId);
        return this.http.post(fullUrl, data);
    };
    RequestUtilService.prototype.getChartInsideData = function (dashBoardObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
        var data = this.ossHelperService.buildRequestBodyForChartInsideData(dashBoardObj);
        return this.http.post(fullUrl, data);
    };
    RequestUtilService.prototype.getChartInsideDataTest = function (dashBoardObj) {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
        var data = this.ossHelperService.buildRequestBodyForChartInsideData(dashBoardObj);
        return this.http.post(fullUrl, data).pipe(map(function (value) { return value; }));
    };
    RequestUtilService.prototype.getDashboardServiceInfo = function () {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + serviceInfoUrl;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    RequestUtilService.prototype.getUtilityServiceInfo = function () {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.utilityServiceUrl + serviceInfoUrl;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    RequestUtilService.prototype.getCoreServiceInfo = function () {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + serviceInfoUrl;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    RequestUtilService.prototype.getNotificationServiceInfo = function () {
        var fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.notificationServiceUrl + serviceInfoUrl;
        return this.http.get(fullUrl).pipe(map(function (value) { return value; }));
    };
    RequestUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RequestUtilService_Factory() { return new RequestUtilService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.OssHelperService)); }, token: RequestUtilService, providedIn: "root" });
    return RequestUtilService;
}());
export { RequestUtilService };
