import * as i0 from "@angular/core";
import * as i1 from "../../login/storage.service";
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService(storageService) {
        this.storageService = storageService;
    }
    LocalStorageService.prototype.getRegisteredUrlData = function () {
        return this.storageService.read('registered_url');
    };
    // getCodeStatusData(): CodeStatus[] {
    //   return this.storageService.read('code_status');
    // }
    LocalStorageService.prototype.getOauthData = function () {
        return this.storageService.read('oauth_data');
    };
    LocalStorageService.prototype.getOauthToken = function () {
        return this.storageService.read('oauth_token');
    };
    LocalStorageService.prototype.getJWTToken = function () {
        return this.storageService.read('jwt_token');
    };
    LocalStorageService.prototype.getExpireInJWTToken = function () {
        return this.storageService.read('jwt_expireIn');
    };
    LocalStorageService.prototype.getDashboardObjs = function () {
        return this.storageService.read('dashboard_obj');
    };
    LocalStorageService.prototype.getDashboardFilterKey = function () {
        return this.storageService.read('dashboardFilter') === null ? 'this_month' : this.storageService.read('dashboardFilter');
    };
    LocalStorageService.prototype.getUserId = function () {
        return this.storageService.read('userId');
    };
    LocalStorageService.prototype.saveUserCredentials = function (oauthData, oauthToken) {
        this.storageService.save('oauth_data', oauthData);
        this.storageService.save('oauth_token', oauthToken);
    };
    LocalStorageService.prototype.saveUserId = function (userId) {
        this.storageService.save('userId', userId);
    };
    LocalStorageService.prototype.setJWTToken = function (jwttoken) {
        return this.storageService.save('jwt_token', jwttoken);
    };
    LocalStorageService.prototype.setExpireInJWTToken = function (expireIn) {
        return this.storageService.save('jwt_expireIn', expireIn);
    };
    LocalStorageService.prototype.saveCallbackUrl = function (url) {
        this.storageService.save('callback_url', url);
    };
    // saveCodeStatus(codeStatusList: CodeStatus[]) {
    //   this.storageService.save('code_status', codeStatusList);
    // }
    LocalStorageService.prototype.saveRegisteredUrl = function (registeredUrlList) {
        this.storageService.save('registered_url', registeredUrlList);
    };
    LocalStorageService.prototype.saveDashboardObjs = function (dashboardObjList) {
        this.storageService.save('dashboard_obj', dashboardObjList);
    };
    LocalStorageService.prototype.saveDashboardMenus = function (dashboardMenuList) {
        this.storageService.save('dashboard_menu', dashboardMenuList);
    };
    LocalStorageService.prototype.getDashboardMenus = function () {
        return this.storageService.read('dashboard_menu');
    };
    LocalStorageService.prototype.saveDashboardFilterKey = function (st) {
        // console.log('Timeline Filter === >>>', st);
        this.storageService.save('dashboardFilter', st);
    };
    LocalStorageService.prototype.saveCurrentDashboard = function (obj) {
        this.storageService.save('currentDashboardObj', obj);
    };
    LocalStorageService.prototype.getCurrentDashboard = function () {
        return this.storageService.read('currentDashboardObj');
    };
    LocalStorageService.prototype.saveAdmin = function (obj) {
        this.storageService.save('admin', obj);
    };
    LocalStorageService.prototype.getAdmin = function () {
        return this.storageService.read('admin');
    };
    LocalStorageService.prototype.saveIsTv = function (obj) {
        this.storageService.save('isTv', obj);
    };
    LocalStorageService.prototype.getIsTv = function () {
        return this.storageService.read('isTv');
    };
    LocalStorageService.prototype.saveReportFormData = function (obj) {
        this.storageService.save('reportFormData', obj);
    };
    LocalStorageService.prototype.getReportFormData = function () {
        return this.storageService.read('reportFormData');
    };
    LocalStorageService.prototype.saveReportExplorerData = function (tableData, displayedColumns, formFields, obj, reportTitle) {
        var reportExploreData = {
            tableData: tableData,
            displayedColumns: displayedColumns,
            formFields: formFields,
            params: obj.params,
            reportTitle: reportTitle
        };
        this.storageService.save('reportExploreData', reportExploreData);
    };
    LocalStorageService.prototype.getReportExploreData = function () {
        return this.storageService.read('reportExploreData');
    };
    LocalStorageService.prototype.saveUserInfoForEmailLogin = function (info) {
        this.storageService.save('userInfoByEmail', info);
    };
    LocalStorageService.prototype.getUserInfoForEmailLogin = function () {
        return this.storageService.read('userInfoByEmail');
    };
    LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(i0.ɵɵinject(i1.StorageService)); }, token: LocalStorageService, providedIn: "root" });
    return LocalStorageService;
}());
export { LocalStorageService };
